import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Home/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "booking",
    component: HomeView,
    // component: () =>
    //   import(
    //     /* webpackChunkName: "PcBooking" */ "../views/PcBooking/PcBooking.vue"
    //   ),
  },
  {
    path: "/NewsList",
    name: "newsList",
    redirect: "/NewsList/more",
    component: () =>
      import(
        /* webpackChunkName: "NewsList" */ "../views/NewsList/NewsList.vue"
      ),
    children: [
      {
        path: "news",
        name: "news",
        component: () =>
          import(
            /* webpackChunkName: "News" */ "../views/NewsList/pages/News/index.vue"
          ),
      },
      {
        path: "notice",
        name: "notice",
        component: () =>
          import(
            /* webpackChunkName: "notice" */ "../views/NewsList/pages/Notice/index.vue"
          ),
      },
      {
        path: "activity",
        name: "activity",
        component: () =>
          import(
            /* webpackChunkName: "activity" */ "../views/NewsList/pages/Activity/index.vue"
          ),
      },
      {
        path: "more",
        name: "more",
        component: () =>
          import(
            /* webpackChunkName: "More" */ "../views/NewsList/pages/More/index.vue"
          ),
      },
    ],
  },
  {
    path: "/articleDetails/:id",
    name: "ArticleDetails",
    component: () =>
      import(
        /* webpackChunkName: "ArticleDetails" */ "../views/ArticleDetails/ArticleDetails.vue"
      ),
  },
  {
    path: "/main",
    name: "main",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scroll(0, 0);
  },
});

export default router;
