import { createStore } from "vuex";
import {
  HomeDownloadQRcodeVideo,
  HomeDownloadQRcodeVideoI,
  getImages,
} from "@/api/indexPC";

export interface GlobalState {
  prosperityObj: HomeDownloadQRcodeVideoI;
  imageObj: any;
}

export default createStore<GlobalState>({
  state() {
    return {
      prosperityObj: {},
      imageObj: {},
    };
  },
  getters: {},
  mutations: {
    setProsperityObj(state, payload) {
      state.prosperityObj = payload;
    },
    setImageObj(state, payload) {
      state.imageObj = payload;
    },
  },
  actions: {
    async getProsperity({ commit }) {
      const { data } = await HomeDownloadQRcodeVideo();
      commit("setProsperityObj", data);
    },
    async getImageObj({ commit }) {
      const { data } = await getImages();
      commit("setImageObj", data);
    },
  },
  modules: {},
});
