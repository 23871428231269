import useFetch from "@/hook/useFetch";
import request from "@/utils/request";
import { AxiosResponse } from "axios";

/**
 * ios 安卓 二维码 视频 下载链接
 */
export interface HomeDownloadQRcodeVideoI {
  code?: string;
  description?: string;
  dow?: string;
  dow_code?: string;
  ios_dow?: string;
  keywords?: string;
  move?: string;
  title?: string;
}
export function useHomeDownloadQRcodeVideo() {
  return useFetch<HomeDownloadQRcodeVideoI>("/Htang/getSiteInfo");
}

export function HomeDownloadQRcodeVideo(): Promise<
  AxiosResponse<HomeDownloadQRcodeVideoI, any>
> {
  return request("/Htang/getSiteInfo");
}

// 站点全部图片
export function getImages() {
  return request("/Htang/getImages");
}

// 首页资讯
export function useHomeNews(num = 4) {
  return useFetch<any>("/Htang/news", "get", {
    num: num,
    page: 1,
  });
}

// 首页图片资讯
export function useHomeNewsSwiper(num = 4) {
  return useFetch<any>("/Htang/news", "get", {
    num: num,
    page: 1,
    top: 1,
  });
}

// 更多资讯
export function getMoreInfo(page = 1, num = 5) {
  return request("/Htang/news", "get", {
    num,
    page,
  });
}

/**
 *
 * @param page
 * @param num
 * @param type 1:新闻 2:公告 3:活动
 */
export function getMoreInfoType(page = 1, num = 5, type: 1 | 2 | 3) {
  return request("/Htang/news", "get", {
    num,
    page,
    type,
  });
}

// 古城风光
export function getAncientCity() {
  return request("/Htang/scenery");
}

// 游戏特色
export function getGameFeatures() {
  return request("/Htang/gameFeature");
}

// 画界名人
export function getCelebrity() {
  return request("/Htang/celebrity");
}

// 游戏社区
export function getGameCommunity() {
  return request("/Htang/bbs");
}

// 三级页面资讯
export function useFetchNews(new_id: string | number) {
  return useFetch<any>("/Htang/newInfo", "get", {
    new_id,
  });
}

// 预约人数
export function useFetchBookingNumber() {
  return useFetch<any>("/Htang/getBookGame", "get");
}

// 发送验证码
export function sendCode(phone: string) {
  return request("/login/sendSms", "post", {
    phone,
  });
}

// 注册
export function activeLogin(phone: string, code: string, invite: string) {
  return request("/login/activeLogin", "post", {
    phone,
    code,
    invite,
  });
}

// 用户信息
export function userInfo(uid: string, VerifyToken: string) {
  return request("/games/userinfo", "post", {
    uid,
    VerifyToken,
  });
}

// 邀请码
export function inviteCode(uid: string, VerifyToken: string) {
  return request("/games/inviteCode", "post", {
    uid,
    VerifyToken,
  });
}

// 预约
export function bookingGame(uid: string, VerifyToken: string) {
  return request("/games/focus", "post", {
    uid,
    VerifyToken,
  });
}

/**
 * @param name
 * @param pc 1:电脑端 2:移动端
 */
// 埋点
export function buriedPoint(name: string, pc: number) {
  return request("/htang/stat", "post", {
    name,
    pc,
  });
}
