import axios, { Method } from "axios";

const config = {
  baseURL: "https://huatangadmin.ilogin.com.cn/api", //https://huatangadmin.ilogin.com.cn/api
  timeout: 20 * 1000, // Timeout,
  // withCredentials: false, // Check cross-site Access-Control
  header: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With,Content-Type",
    "Access-Control-Allow-Methods": "PUT,POST,GET,DELETE,OPTIONS",
    "Content-Type": "application/json;charset=utf-8",
  },
};

const _axios = axios.create(config);

// 请求拦截器
_axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
_axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code === 0) {
        return response.data;
      }

      if (response.data.code === -555) {
        return Promise.reject({
          code: -555,
          message: response.data.msg,
        });
      }
    }
    if (response.status === 404) {
      return Promise.reject({
        message: "请求资源不存在",
        status: 404,
      });
    }
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
const generateRequestConfig = (url: string, method: Method, data: any) => ({
  url,
  method,
  [method.toLowerCase() === "get" ? "params" : "data"]: data,
});

export default function request(
  url: string,
  method: Method = "get",
  data?: any
) {
  return _axios(generateRequestConfig(url, method, data));
}
