import { ref } from "vue";
import request from "@/utils/request";
import { Method } from "axios";

/**
 * 请求 hook
 */
export default function useFetch<T>(
  url: string,
  method: Method = "get",
  pdata?: any
) {
  const data = ref<T>();
  const error = ref();
  const isLoading = ref(false);

  async function fetchData() {
    isLoading.value = true;
    try {
      const response = await request(url, method, pdata);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.value = await response;
      error.value = undefined;
    } catch (err) {
      data.value = undefined;
      error.value = err;
    }
    isLoading.value = false;
  }
  fetchData();

  return { isLoading, error, data };
}
